import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    var coordinates = [7.591475, 50.35508]

    // dynamic import (mapbox-gl will only be fetched from the server when needed)
    return import(/* webpackChunkName: "mapbox-gl" */ "mapbox-gl").then(
      ({ default: mapboxgl }) => {
        mapboxgl.accessToken =
          "pk.eyJ1IjoiZm9ybXJhdXNjaCIsImEiOiJzUkxfYTA4In0.REy8tw_FMvmG7Tibs8T8mg"
        var map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v9?optimize=true",
          center: coordinates,
          zoom: 16
        })

        var nav = new mapboxgl.NavigationControl()
        map.addControl(new mapboxgl.NavigationControl(), "bottom-right")

        map.scrollZoom.disable()

        var el = document.createElement("div")
        el.className = "marker"

        new mapboxgl.Marker(el).setLngLat(coordinates).addTo(map)
      }
    )
  }

  disconnect() {}
}
